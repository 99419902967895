import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notif-intervention-en-cours',
  templateUrl: './notif-intervention-en-cours.component.html',
  styleUrls: ['./notif-intervention-en-cours.component.scss'],
})
export class NotifInterventionEnCoursComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
