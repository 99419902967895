<ion-content>
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <div class="logo-container">
          <ion-img src="/assets/img/logo_mon_agil.png"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <form [formGroup]="form">
          <div class="items-container">
            <ion-item>
              <ion-label position="floating">Email</ion-label>
              <ion-input minlength="6" type="email" formControlName="email"></ion-input>
            </ion-item>
          </div>

          <div class="button-container">
            <ion-button [attr.disabled]="form.invalid" (click)="reset()">
              <ion-label > <ion-spinner name="circle" *ngIf="loading"></ion-spinner>Valider</ion-label>
            </ion-button>
          </div>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
