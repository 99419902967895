<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        Vous avez sollicité un devis pour <span class="txt-agil">3 logements</span>. Votre demande a bien été prise en
        compte !
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="../../../../assets/img/illustration_menage_no_background.png"></ion-img>
        <ion-icon class="checkmarkcirclee" (click)="dismiss()" name="checkmark-circle"></ion-icon>
      </div>
    </ion-col>
  </ion-row>
</ion-content>