import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-notif-suppression-logement',
  templateUrl: './notif-suppression-logement.component.html',
  styleUrls: ['./notif-suppression-logement.component.scss'],
})
export class NotifSuppressionLogementComponent implements OnInit {
  details;
  constructor(private navParams: NavParams, private modalController: ModalController) { }

  ngOnInit() {
    this.details = this.navParams.get('details')
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  deleteLogement() {
    this.modalController.dismiss({
      'delete': true
    });
  }
}
