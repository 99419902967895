import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

type Position = 'top' | 'bottom' | 'middle';
@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    constructor(public toastController: ToastController) {}

    async presentToast(msg: string, color: string = 'warning', duration: number = 3000, position: Position = 'top') {
        const toast = await this.toastController.create({
            message: msg,
            color,
            duration,
            position
        });
        await toast.present();
    }
}
