import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ResetPage } from 'src/app/reset/reset.page';
import {NotifSuppressionProfilComponent} from '../../compte-gestionnaire/notifications/notif-suppression-profil/notif-suppression-profil.component';
import { AuthService } from 'src/app/common/auth.service';

@Component({
  selector: 'app-menu-popover-artisan',
  templateUrl: './menu-popover-artisan.component.html',
  styleUrls: ['./menu-popover-artisan.component.scss'],
})
export class MenuPopoverArtisanComponent implements OnInit {

  constructor(private storage: Storage, 
              private auth: AuthService,
              private router: Router, 
              private modalController: 
              ModalController) { }

  ngOnInit() { }

  async presentSuppressionModal() {
    const modal = await this.modalController.create({
      component: NotifSuppressionProfilComponent,
      cssClass: 'css-notification-blue-backdrop',
      componentProps: { artisan: true }
    });
    return await modal.present();
  }

  toMonAbonnement() {
    return this.router.navigateByUrl('/compte-artisan/abonnement-artisan')
  }

  disconnect() {
    this.auth.disconnect();
  }

  toContact() {
    return window.open('https://monagil.fr/contact/', '_blank')
  }

  toAide() {

    return window.open('https://monagil.fr/aide/', '_blank')
  }

  async presentResetPassword() {
    const modal = await this.modalController.create({
      component: ResetPage,

    });
    return await modal.present();
  }
}
