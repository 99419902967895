<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        Quelles problèmes avez-vous rencontré suite à cette intervention ?
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-textarea maxlength="255" placeholder="Commentaire"></ion-textarea>
      <p id="limiteNotify">Limité à 255 caractères</p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="/assets/img/illustration_menage_no_background.png"></ion-img>
        <ion-button>Valider</ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
