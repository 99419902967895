import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-notif-intervention-en-cours-realisation',
  templateUrl: './notif-intervention-en-cours-realisation.component.html',
  styleUrls: ['./notif-intervention-en-cours-realisation.component.scss'],
})
export class NotifInterventionEnCoursRealisationComponent implements OnInit {
  nom: string;
  constructor(private navParams: NavParams, private modalController: ModalController) {
    this.navParams.get('nom');
  }

  ngOnInit() { }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
