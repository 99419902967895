import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-notif-devis',
  templateUrl: './notif-devis.component.html',
  styleUrls: ['./notif-devis.component.scss'],
})
export class NotifDevisComponent implements OnInit {

  constructor(private modalController: ModalController
  ) { }

  ngOnInit() { }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
