<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        Voulez-vous accepter la demande d’intervention <span class="nom-intervention">{{details}} ?</span>
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="../../../../assets/img/illustration_menage_no_background.png"></ion-img>
        <ion-button (click)="accept()" class="valider">Oui</ion-button>
        <ion-button (click)="dismiss()" class="annuler">Non</ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-content>