import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpClient,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {Observable, from, of, BehaviorSubject} from 'rxjs';
import {AuthService} from './auth.service';
import {switchMap, map, catchError} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {AlertController, LoadingController, ToastController} from '@ionic/angular';
import {environment} from 'src/environments/environment';
import {ToasterService} from './toaster.service';

const TOKEN_KEY = 'access_token' + (environment.production ? '' : '_dev');
const REFRESH_TOKEN_KEY = 'refresh_token' + (environment.production ? '' : '_dev');

@Injectable({
    providedIn: 'root'
})


export class TokenInterceptorService implements HttpInterceptor {
    isOnline: BehaviorSubject<boolean> = new BehaviorSubject(false);
    token: string;
    api: string = environment.api_url + '/api/';

    constructor(private loadingController: LoadingController,
                private auth: AuthService,
                private alertController: AlertController,
                private router: Router,
                private http: HttpClient,
                private helper: JwtHelperService,
                public toastService: ToasterService,
                private storage: Storage) {
    }

    // Récupère le token pour l'introduire dans le header de la requête afin qu'il soit vérifié côté serveur
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.storage.get(TOKEN_KEY))
            .pipe(
                switchMap(token => {
                    if (token) {
                        const isExpired = this.helper.isTokenExpired(token);
                        if (!isExpired && request.url.includes(this.api) && !request.url.includes('login_check')) {
                            request = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + token)});
                        }
                    }

                    return next.handle(request).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                            }
                            return event;
                        }),
                        catchError((error: HttpErrorResponse) => {
                            const status = error.status;
                            const errorsObj = error.error.errors?.children;
                            let message: string;
                            if (errorsObj) {
                                for (let [key, value] of Object.entries(errorsObj)) {
                                    if (Object.keys(value).length !== 0) {
                                        message = value['errors'];
                                    }
                                }
                            }
                            const reason = message ? message : error.error.message;
                            const isExpired = this.helper.isTokenExpired(token);
                            if (status === 0 || (status === 401 && isExpired && !request.url.includes('login_check'))) {
                                return this.storage.remove(TOKEN_KEY).then((res) => this.router.navigateByUrl('home')).then(() => of(null));
                            } else if (status === 400 || status === 403 || status === 404 || status === 401) {
                                this.toastService.presentToast(reason, 'error');
                                this.loadingController.getTop().then(l => l ? this.loadingController.dismiss() : null);
                                return of(null);
                            } else if (status === 500 || status === 503) {
                                this.toastService.presentToast(`Une erreur s'est produite sur le serveur`, 'error');
                                this.loadingController.getTop().then(l => l ? this.loadingController.dismiss() : null);
                                return of(null);
                            } else if (!window.navigator.onLine) {
                                if (!this.isOnline.value) {
                                    this.isOnline.next(true);
                                    this.toastService.presentToast(`Vérifiez votre connexion internet`, 'error');
                                    return of(null);
                                }
                                return of(null);

                            } else {
                                return of(null);
                            }
                        })
                    );
                })
            );
    }
}
