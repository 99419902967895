<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        L’intervention <span class="txt-agil">{{nom}}</span> est en cours de réalisation !
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="/assets/img/ajout_logement_illustration.png"></ion-img>
        <ion-icon class="checkmarkcirclee" (click)="dismiss()" name="checkmark-circle"></ion-icon>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
