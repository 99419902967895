<ion-content [fullscreen]="true">
    <ion-row>
        <ion-col>
            <p>
                Vous venez de recevoir une demande d'intervention ! Rendez-vous dans la to-do-list pour la visualiser.
            </p>
        </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="button-container">
          <ion-img src="/assets/img/Illustration_notification_intervention_annulee.png"></ion-img>
          <ion-icon class="checkmarkcirclee" (click)="dismiss()" name="checkmark-circle"></ion-icon>
        </div>
      </ion-col>
    </ion-row>
</ion-content>
