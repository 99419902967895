import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notification-profil-incomplet-artisan',
  templateUrl: './notification-profil-incomplet-artisan.component.html',
  styleUrls: ['./notification-profil-incomplet-artisan.component.scss'],
})
export class NotificationProfilIncompletArtisanComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  dismiss() {
    return this.modalController.dismiss()
  }
}
