<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        Complétez votre profil et ajouter la copie de la Pièce d'identité du titulaire du compte afin d’utiliser pleinement <span class="txt-agil">Mon AGIL</span> !
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="/assets/img/illustration_menage_no_background.png"></ion-img>
        <ion-button (click)="dismiss()">Valider</ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
