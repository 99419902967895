<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>Renseignez votre zone d'intervention ainsi que toutes les données de votre profil afin de réaliser votre
        première intervention !</p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
        <div class="button-container">
          <ion-img src="../../../../assets/img/illustration_menage_no_background.png"></ion-img>
          <ion-button (click)="dismiss()">Valider</ion-button>
        </div>
    </ion-col>
  </ion-row>
</ion-content>