<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        Voulez-vous vraiment annuler l'intervention <span class="nom-intervention">{{nom_intervention}}</span> ?
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="/assets/img/Illustration_notification_intervention_annulee.png"></ion-img>
        <ion-button (click)="cancelIntervention()" class="valider">Oui</ion-button>
        <ion-button (click)="dismiss()" class="annuler">Non</ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-content>