import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notif-annulation-intervention',
  templateUrl: './notif-annulation-intervention.component.html',
  styleUrls: ['./notif-annulation-intervention.component.scss'],
})
export class NotifAnnulationInterventionComponent implements OnInit {
  nom_intervention: string;
  constructor(private navParams: NavParams, private router: Router, private modalController: ModalController) {
    this.nom_intervention = this.navParams.get('nom')
  }

  ngOnInit() { }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  cancelIntervention() {
    this.modalController.dismiss({
      'cancel': true
    });
  }
}
