import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { GoogleAuthService } from 'ng-gapi/lib/src';

const TOKEN_KEY = 'access_token' + (environment.production ? '' : '_dev');
const REFRESH_TOKEN_KEY = 'refresh_token' + (environment.production ? '' : '_dev');
const ROLE = 'role_user';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = null;
  token = new BehaviorSubject(null);

  constructor(private googleAuth: GoogleAuthService,
              private router: Router,
              private http: HttpClient,
              private helper: JwtHelperService,
              private storage: Storage,
  ) {
    if (!environment.production) {}
  }

  async isAuthenticated() {

    return this.storage.get(TOKEN_KEY).then((token) => {
      if (token) {
        return this.storage.get(ROLE).then(role => {
          if (role === 'ROLE_GESTIONNAIRE') {
            return this.router.navigateByUrl('/compte-gestionnaire');
          } else if (role === 'ROLE_ARTISAN') {
            return this.router.navigateByUrl('/compte-artisan');
          }
        });
      }
    });
  }

  logIn(username: string, password: string) {
    return this.http.post(environment.api_url + '/api/login_check', { username, password }).pipe(
      tap((res) => {
        if (res) {
          this.storage.set(TOKEN_KEY, res['token']);
          this.storage.set(REFRESH_TOKEN_KEY, res['refresh_token']);
          const user = this.helper.decodeToken(res['token']);
          this.storage.set(ROLE, user.roles[0]);
          const redirectUrl = user.roles[0] === 'ROLE_GESTIONNAIRE' ? '/compte-gestionnaire' : '/compte-artisan';
          if (localStorage.getItem('tutorial_shown_' + user.username)) {
            this.router.navigateByUrl(redirectUrl);
          } else {
            localStorage.setItem('tutorial_shown_' + user.username, '1');
            localStorage.setItem('mode_tuto', redirectUrl.replace('/compte-', ''));
            this.router.navigateByUrl('onboarding');
          }
        }
      })
    );
  }

  async logout() {
    return await this.storage.remove(TOKEN_KEY).then(() => {
      return this.storage.remove(REFRESH_TOKEN_KEY).then(() => this.storage.remove(ROLE)).then(() => this.router.navigateByUrl('home'))
    });
  }

  googleLogIn(role) {
    return this.googleAuth.getAuth().subscribe(auth => auth.signIn().then(res => {
      const access_token = res['wc'].access_token;
      return this.http.post(environment.api_url + '/api/register/google_authentification/',
          // tslint:disable-next-line:no-shadowed-variable
          { access_token, role }).subscribe(res => {
        if (res) {
          this.storage.set(TOKEN_KEY, res['token']);
          this.storage.set(REFRESH_TOKEN_KEY, res['refresh_token']);
          const user = this.helper.decodeToken(res['token']);
          this.storage.set(ROLE, user.roles[0]);
          // tslint:disable-next-line:max-line-length
          user.roles[0] === 'ROLE_GESTIONNAIRE' ? this.router.navigateByUrl('/compte-gestionnaire') : this.router.navigateByUrl('/compte-artisan');
        }
      });
    }));
  }

  resetPassword(email) {
    const formData = new FormData();
    formData.append('email', email);
    return this.http.post(environment.api_url + '/api/register/reset_password', formData);
  }

  disconnect() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.storage.remove(REFRESH_TOKEN_KEY);
      window.location.href = '/home';
    });
  }

  confirmAccount(token) {
    return this.http.get(environment.api_url + `/register/validate_email/${token}`);
  }
}
