import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notif-suppression-profil',
  templateUrl: './notif-suppression-profil.component.html',
  styleUrls: ['./notif-suppression-profil.component.scss'],
})
export class NotifSuppressionProfilComponent implements OnInit {
  artisan: boolean;
  gestionnaire: boolean;
  constructor(private navParams: NavParams, private router: Router, private modalController: ModalController
  ) {
    this.user()
  }

  ngOnInit() { }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  user() {
    const gestionnaire = this.navParams.get('gestionnaire')
    const artisan = this.navParams.get('artisan')

    return gestionnaire ? this.gestionnaire = gestionnaire : this.artisan = artisan
  }

  toSuppressionProfil() {
    return this.router.navigateByUrl('/suppression-profil', { state: { 'user': this.gestionnaire ? { 'gestionnaire': this.gestionnaire } : { 'artisan': this.artisan } } })
  }
}
