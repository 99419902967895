<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        Souhaitez-vous supprimer votre profil <span class="txt-agil">Mon AGIL</span> ?
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="/assets/img/Illustration_notification_intervention_annulee.png"></ion-img>
        <ion-button (click)="toSuppressionProfil()" class="valider">Valider</ion-button>
        <ion-button (click)="dismiss()" class="annuler">Annuler</ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
