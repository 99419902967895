<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        Votre demande d’intervention a été <span class="txt-agil">acceptée</span> avec succès. Sélectionnez-la pour la confirmer !
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="/assets/img/illustration_menage_no_background.png"></ion-img>
        <ion-icon class="checkmarkcirclee" (click)="dismiss()" name="checkmark-circle"></ion-icon>
      </div>
    </ion-col>
  </ion-row>
</ion-content>