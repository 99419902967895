import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable, throwError} from 'rxjs';
import {AlertController, LoadingController} from '@ionic/angular';
import {catchError, delay, finalize, map, retryWhen, take, tap} from 'rxjs/operators';
import {ToasterService} from '../common/toaster.service';
import {Router} from '@angular/router';

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
    constructor(private loadingCtrl: LoadingController,
                private alertCtrl: AlertController,
                private router: Router,
                private toastService: ToasterService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('get_interventions') === -1
            && req.url.indexOf('categorie_logement') === -1
            && req.url.indexOf('api-adresse.data.gouv.fr') === -1
            && req.url.indexOf('get_demandes_recus') === -1 ) {
            this.loadingCtrl.getTop().then(hashLoading => {
                if (!hashLoading) {
                    this.loadingCtrl.create({
                        spinner: null,
                        duration: 2000,
                        message: '<ion-img src="assets/img/logo.png"></ion-img>',
                        cssClass: 'custom-loading'
                    }).then(loading => loading.present());
                }
            });
            return next.handle(req).pipe(
                retryWhen(err => {
                    let retries = 1;
                    return err.pipe(
                        delay(1000),
                        map(error => {
                            if (retries++ === 3) {
                                throw error;
                            }
                            return error;
                        })
                    );
                }),
                catchError(err => {
                    console.log(err)
                    this.presentFailedAlert(err.error.message);
                    this.router.navigate(['/home']);
                    return EMPTY;
                }),
                finalize(() => {
                    this.loadingCtrl.getTop().then(hasLoading => {
                        if (hasLoading) {
                            this.loadingCtrl.dismiss().then(() => console.log('abort presenting'));
                        }
                    });
                })
            );
        }

        return next.handle(req);
    }


   async presentFailedAlert(msg) {
        const alert = await this.alertCtrl.create({
            header: 'Oops',
            message: msg,
            buttons: ['OK']
        });
        await alert.present();
   }
}
