import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-notification-intervention-validee',
  templateUrl: './notification-intervention-validee.component.html',
  styleUrls: ['./notification-intervention-validee.component.scss'],
})
export class NotificationInterventionValideeComponent implements OnInit {
  nom: string;
  constructor(private navParams: NavParams, private modalController: ModalController) {
    this.nom = this.navParams.get('nom')
  }

  ngOnInit() { }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
