import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../common/auth.service';
import { ModalController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.page.html',
  styleUrls: ['./reset.page.scss'],
})
export class ResetPage implements OnInit {
  form: FormGroup;
  loading: boolean;
  constructor(private toastController: ToastController, private modalController: ModalController, private authService: AuthService, private fb: FormBuilder) {

    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]]
    })
  }

  ngOnInit() {
  }
  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Un email vous a été envoyé !',
      cssClass: 'css-success-toast',
      duration: 2000
    });
    toast.present();
  }

  reset() {
    this.loading = true;
    const val = this.form.value
    return this.authService.resetPassword(val.email).subscribe(res => {
      this.modalController.dismiss().then(() => this.presentToast())
      this.loading = false;
    })
  }
}
