<ion-content [fullscreen]="true">
  <ion-row class="ion-padding">
    
    <ion-col id="notif">
      <div>
        <p>L'intervention <span class="txt-agil">ménage_06</span> a été modifiée !
        </p>
      </div>
      <div>
        <ion-icon class="checkmarkcirclee" (click)="dismiss()" name="checkmark-circle"></ion-icon>
      </div>
      <div class="img-container">
        <ion-img src="/assets/img/Illustration_notification_intervention_annulee.png"></ion-img>
      </div>
    </ion-col>


  </ion-row>
</ion-content>