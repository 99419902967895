
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../common/auth.service';

@Injectable({ providedIn: 'root' })
export class IsAuthenticatedService implements Resolve<Observable<any>> {
  constructor(private router: Router, private auth: AuthService) { }
  resolve(): Promise<any> {
    return this.auth.isAuthenticated().catch(() => this.router.navigateByUrl('home'))
  }
}