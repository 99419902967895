import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notif-profil-incomplet',
  templateUrl: './notif-profil-incomplet.component.html',
  styleUrls: ['./notif-profil-incomplet.component.scss'],
})
export class NotifProfilIncompletComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() { }


  dismiss() {
    return this.modalController.dismiss()
  }
}
