<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col>
      <p>
        Pensez à changer <span class="txt-agil">le statut de votre intervention</span> lorsque celle-ci est en cours !
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="button-container">
        <ion-img src="/assets/img/Illustration_notification_intervention_annulee.png"></ion-img>
        <ion-icon class="checkmarkcirclee" (click)="dismiss()" name="checkmark-circle"></ion-icon>
      </div>
    </ion-col>
  </ion-row>
</ion-content>