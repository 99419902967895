import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notif-intervention-commentaire',
  templateUrl: './notif-intervention-commentaire.component.html',
  styleUrls: ['./notif-intervention-commentaire.component.scss'],
})
export class NotifInterventionCommentaireComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
