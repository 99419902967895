import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifSuppressionProfilComponent } from '../notifications/notif-suppression-profil/notif-suppression-profil.component';
import { ModalController, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ResetPage } from 'src/app/reset/reset.page';
import {AuthService} from '../../common/auth.service';

@Component({
  selector: 'app-menu-popover',
  templateUrl: './menu-popover.component.html',
  styleUrls: ['./menu-popover.component.scss'],
})
export class MenuPopoverComponent implements OnInit {

  constructor(private storage: Storage,
              private auth: AuthService,
              private modalController: ModalController,
              private router: Router,
              private navParams: NavParams) { }

  ngOnInit() { }

  async presentSuppressionModal() {
    const modal = await this.modalController.create({
      component: NotifSuppressionProfilComponent,
      cssClass: 'css-notification-blue-backdrop',
      componentProps: { gestionnaire: true }
    });
    return await modal.present();
  }

  async presentResetPassword() {
    const modal = await this.modalController.create({
      component: ResetPage,
      cssClass: 'css-notification-blue-backdrop',
    });
    return await modal.present();
  }

  disconnect() {
    this.auth.disconnect();
  }

  toMonAbonnement() {
    // tslint:disable-next-line:triple-equals
    if (this.navParams.get('artisan') == true) {
      return this.router.navigateByUrl('/compte-artisan/abonnement-artisan');
    } else {
      return this.router.navigateByUrl('/compte-gestionnaire/mon-abonnement');
    }
  }

  toContact() {
    return window.open('https://monagil.fr/contact/', '_blank');
  }

  toAide() {
    return window.open('https://monagil.fr/aide/', '_blank');
  }
}
